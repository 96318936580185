aside {
  z-index: 0;
  nav {
    height: calc(100vh - 190px);
    overflow: auto;
  }
  .active {
    @apply text-ThemeBlue relative z-10;
    svg {
      path {
        fill: #1b3f67;
      }
    }
    &:before {
      content: "";
      background-color: #4ee287;
      width: 97%;
      height: 45px;
      position: absolute;
      right: 0;
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
      z-index: -1;
    }
  }
}

.admin {
  th {
    @apply bg-ThemeLightBlue;
    &:first-child {
      @apply rounded-l-xl;
    }
    &:last-child {
      @apply rounded-r-xl;
    }
  }
  .line-menu {
    @apply bg-white;
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-group__input {
    width: 98%;
  }
}

.list-circle {
  position: relative;
  &:before {
    content: "";
    width: 15px;
    height: 15px;
    background-color: #18bf65;
    position: absolute;
    left: 1.4em;
    top: 0.3em;
    border-radius: 50%;
  }
}

.hove-me {
  @apply cursor-pointer;
  &:hover .img-visi {
    @apply block cursor-pointer z-10;
  }
  &:hover img {
    @apply opacity-75;
  }
}

.switch-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

input[type="checkbox"].switch {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #00b0f2;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
  width: 94px;
  background-position: 0 0;
  background-color: #3b89ec;
}

input[type="checkbox"].switch + div {
  width: 94px;
  height: 48px;
}

input[type="checkbox"].switch:checked + div {
  background-color: #57bb00;
}

input[type="checkbox"].switch + div > div {
  float: left;
  width: 46px;
  height: 46px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
  width: 44px;
  height: 44px;
  margin-top: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(46px, 0, 0);
  transform: translate3d(46px, 0, 0);
}

canvas {
  @apply bg-white p-6 rounded-xl border border-borderBlue shadow-xl;
}

.drop-select {
  @apply bg-white text-ThemeBlue p-2 rounded-xl border border-borderBlue;
}
