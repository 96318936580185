@font-face {
  font-family: "Wavehaus bold";
  src: local("Wavehaus bold"), url("/fonts/Wavehaus-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Author bold";
  src: local("Author bold"), url("/fonts/Author/Author-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Author semibold";
  src: local("Author semibold"), url("/fonts/Author/Author-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Author medium";
  src: local("Author medium"), url("/fonts/Author/Author-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}


@font-face {
  font-family: "Author light";
  src: local("Author light"), url("/fonts/Author/Author-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Author thin";
  src: local("Author thin"), url("/fonts/Author/Author-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}


@font-face {
  font-family: "Wavehaus extrabold";
  src: local("Wavehaus extrabold"), url("/fonts/Wavehaus-ExtraBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Wavehaus light";
  src: local("Wavehaus light"), url("/fonts/Wavehaus-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Wavehaus book";
  src: local("Wavehaus book"), url("/fonts/Wavehaus-Book.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Wavehaus semibold";
  src: local("Wavehaus semibold"), url("/fonts/Wavehaus-SemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Wavehaus thin";
  src: local("Wavehaus thin"), url("/fonts/Wavehaus-Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Wavehaus book";
}

input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: 0;
}

// .swiper-container {
//   height: 425px;
// }
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 340px !important;
  height: 340px !important;
}
.swiper-container {
  height: 425px;
}
.swiper-container-3d {
  perspective: 800px !important;
}
.section-item {
  h1 {
    font-size: 10em;
  }
}

.strength {
  .swiper-container {
    height: 375px;
    background-position: 248px 65px;
    background-size: 70%;
  }
  .swiper-slide {
    width: 322px !important;
    height: 240px !important;
    img {
      width: 322px !important;
      height: 240px !important;
    }
  }
}

// @media (min-width: 768px) and (max-width: 1023px) {
//   @content;
// }

@media (min-width: 320px) and (max-width: 767px) {
  .swiper-container {
    height: 325px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 280px !important;
    height: 280px !important;
  }
  .strength {
    .swiper-container {
      height: 250px;
      background-position: 99px 65px;
      background-size: 100%;
    }
    .swiper-slide {
      width: 222px !important;
      height: 140px !important;
      img {
        width: 222px !important;
        height: 140px !important;
      }
    }
  }
  .section-item {
    h1 {
      font-size: 8em;
    }
  }
}

td {
  word-break: break-all;
}
.error {
  color: rgb(255, 58, 58);
  font-size: 12px;
  position: absolute;
  width: 265px;
  z-index: 99;
}

//input animation field

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.form {
  height: 100%;
  min-height: 240px;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
}

.form-group {
  position: relative;
  height: 3rem;
  margin: 1em 0;
  &.textarea {
    height: 8rem;
  }
  &.has-error {
    input,
    textarea,
    select {
      border: 1px solid rgb(247, 41, 41);
    }
  }
  &__input {
    @apply text-ThemeBlue font-WavehausSemiBold;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    padding: 1rem;
    background: none;

    &:hover {
      border-color: #1b3f67;
    }

    /* Change border when input focus*/

    &:focus {
      border-color: #1b3f67;
    }
  }
  &__file {
    @apply text-ThemeBlue font-WavehausSemiBold;
    position: absolute;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    border-radius: 10px;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    display: flex;
    align-items: center;
    padding: 1rem;
  }
  select {
    &.form-group__input {
      padding: 0 0.5em !important;
    }
  }
  &__label {
    @apply text-ThemeBlue bg-white;
    position: absolute;
    left: 1rem;
    top: 0.8rem;
    padding: 0;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    z-index: 9;
  }
}

.has-error {
  input,
  textarea,
  select {
    border: 1px solid rgb(247, 41, 41);
  }
}
/* 
1. When the input is in the focus state
reduce the size of the label and move upwards 

2. Keep label state when content is in input field 
*/

.form-group__input:focus ~ .form-group__label,
.form-group__input:not(:placeholder-shown).form-group__input:not(:focus) ~ .form-group__label {
  top: -0.5rem;
  font-size: 0.8rem;
  left: 0.8rem;
}

.textarea-label {
  width: 255px;
  &.full {
    width: 115px;
  }
}

.has-error {
  color: red;
}

table {
  width: 100%;
  th,
  td {
    @apply text-ThemeBlue;
    text-align: left;
    padding: 0.5em;
    .status {
      @apply rounded-full border px-4 py-2 w-40 block text-center h-10;
      &.green {
        @apply border-green text-green;
      }
      &.yellow {
        @apply border-yellow text-yellow;
      }
    }
  }
}

.paginate_button {
  @apply mx-1;
}
.swiper-pagination-bullet-active {
  background-color: #1e4269 !important;
}

.coming-soon{
  text-align: center;
    max-height: 231px;
    height: 231px;
}

.body2 {
  @apply text-white text-xl
}


.know-more-btn {
  border-radius: 12px;
  border: 1px solid #3da07c;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}

.outlined-btn {
  border-radius: 12px;
  border: 1px solid #1c3f67;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}

.blur-card-bg {
  // background: rgba(255, 255, 255, 0.26);
  background:rgba(239, 247, 255, 1);
  backdrop-filter: blur(11.5px);
}

.who-we-are {

  @media screen and (max-width: 768px) {
    background-image: linear-gradient(10deg,
    #F7F7F7 4.63%,
    rgba(192, 255, 216, 0) 48.33%),
    url('./images/who-we-are.webp');
    background-size: cover;
  }

  @media screen and (min-width: 769px) {
    background-image: linear-gradient(88deg,
    #F7F7F7 4.63%,
    rgba(234, 250, 255, 0) 48.33%),
    url('./images/who-we-are.webp');
    background-size: cover;
}

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    background-image: linear-gradient(10deg,
    #F7F7F7 4.63%,
      rgba(234, 250, 255, 0) 48.33%),
      url('./images/who-we-are.webp'); 
    background-size: cover;
  }
}
   
  

.text-input {
  border-radius: 6px;
  border: 1px solid rgba(61, 87, 126, 0.45);
  background: rgba(255, 255, 255, 0.38);
}

.why-choose-us {
  border-radius: 12px;
  background: linear-gradient(163deg,
      #333186 1.59%,
      #232e69 40.58%,
      #0e3662 96.36%);
}

.ring1{
  border: 1px dashed rgba(28, 63, 103, 0.20);
}
.ring2{
  border: 1px dashed rgba(28, 63, 103, 0.30);
}
.ring3{
  border: 1px dashed rgba(28, 63, 103, 0.65);
}
.ring4{
  border: 1px dashed rgba(28, 63, 103, 0.40);
}


.scaleIcon {
  scale: 2.5;
  
}

.choose-card {
  border-radius: 12px;
  background: rgba(141, 150, 233, 0.21);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
}

@mixin clamp-text-size($min-size, $preferred-size, $max-size) {
  font-size: clamp(#{$min-size}, #{$preferred-size}, #{$max-size});
}




.title {
  font-size: clamp(18px, 3vw, 36px);
}



::-moz-selection{
  color:'white';
  background:'#FFFFFF';
}

* {
 
  -webkit-tap-highlight-color: transparent;
}


.circle-spacing {
  border-width: 3px;

}


.textInput {
  border: 1px solid rgba(61, 87, 126, 0.20)
}



.title1 {
  font-family: "Author semibold", sans-serif;
  // font-size: calc(32px + 1vw);
  @apply leading-tight text-mobile md:text-tab lg:text-desktop

}

.heading1 {
  font-size: clamp(18px, 5vw, 28px);
  font-family: "Author semibold", sans-serif;
}

.heading2 {
  @apply leading-tight text-7vw md:text-5vw lg:text-1.7vw;
  font-family: "Author semibold", sans-serif;
}

.sub-heading1{
  font-size: clamp(18px, 5vw, 20px);
  font-family: "Author semibold", sans-serif;
}

.sub-heading2{
  font-size: clamp(12px, 5vw, 16px);
  font-family: "Author medium", sans-serif;
}

.body-content1{
  // font-size: clamp(18px, 5vw, 18px);
  font-family: "Author thin", sans-serif;
  @apply leading-tight text-7vw md:text-5vw lg:text-1vw;
}

.btn-content{
  // font-size: clamp(18px, 5vw, 18px);
  font-family: "Author thin", sans-serif;
  @apply leading-tight text-7vw md:text-5vw lg:text-1vw;
}

.body-content2{
  font-size: clamp(18px, 5vw, 20px);
  font-family: "Author medium", sans-serif;
}

textarea:focus {
  border-color: #232E69;
  border-width: 2px;
 
}


.product_bg {
  background: linear-gradient(163deg, #333186 1.59%, #232E69 40.58%, #0E3662 96.36%);
}


.rotateIcon {
  transform: translateX(100%);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 1000ms;
}

.rotateIcon1 {
  // transform: rotate(45deg);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 1000ms;
  transform: translateX(-100%);
}

.reverseIcon {

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 1000ms;
}

.marquee_card {
  border-radius: 12px;
background: rgba(141, 150, 233, 0.21);
box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
}

.reports_card {
  border-radius: 8px;
  background-image: 
  url('./images/bg-animate.png');
  background-size: cover;
border: 1px solid rgba(35, 46, 105, 0.50);
}

.reports_card_button {
  border-radius: 8px;
  
border: 1px solid #1C3F67;
background: rgba(255, 255, 255, 0.24);
box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}






.jobList {
  position: relative;
  li {
    position: relative;
    margin: 0.5em 0;
    padding-left: 1em;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: #5f7995;
      top: 9px;
    }
  }
}




.form-container.has-error textarea {
  border: 1px solid rgb(247, 41, 41);
}

.font-inter{
  font-family: 'Inter', sans-serif;
}

.main-heading {
  @apply text-2xl lg:text-3xl  font-inter   font-bold leading-tight;
}



/* Circle.css */
.circle {
  width: 40%; /* You can adjust the size as needed */
  height: 0;
  padding-bottom: 40%; /* This creates a perfect circle */
  background-color: #3498db; /* You can change the color */
  border-radius: 50%;
}


.cardStyle{
  border-radius: 12px;
  background: rgba(141, 150, 233, 0.21);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
}













































